import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

interface NotFoundPageProps {
  location: Location;
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ location }) => {
  return (
    <Layout>
      <SEO title="404: Not Found" slug={location.pathname} />

      <section>
        <div className="container">
          <h1>Page not found</h1>
          <Link to="/">Return to home</Link>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
